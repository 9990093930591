"use client";
import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";

const Services = () => {
    const [activeTab, setActiveTab] = useState("tab1");
    const Title = ({ text }) => {
        return (
            <h2 className="font44 fw-600 text-center mt-0 title-border mb-50 title-bordercenter">
                {text}
            </h2>
        );
    };

    const TabLink = ({ id, title, imgSrc, alt, isActive, onClick }) => {
        return (
            <li className={isActive ? "active" : ""}>
                <a
                    data-toggle="tab"
                    href={`#${id}`}
                    title={title}
                    onClick={(e) => onClick(e, id)}
                >
                    <span className="linkimg">
                        <Image
                            loading="lazy"
                            as="image"
                            src={imgSrc}
                            alt={alt}
                            width={24}
                            height={24}
                        />
                    </span>
                    <span className="tab-title">{title}</span>
                </a>
            </li>
        );
    };

    const ContentBlock = ({ title, subtitle, description, links }) => {
        return (
            <>
                <div className="block font20 mb-35">
                    <h3 className="font24 fw-500 mb-25 mt-0 lineh-normal d-md-none">
                        {title}
                    </h3>
                    <p className="font24 d-md-block fw-600">{subtitle}</p>
                    {Array.isArray(description) ? (
                        description.map((paragraph, index) => (
                            <p key={index} className="font18">
                                {paragraph}
                            </p>
                        ))
                    ) : (
                        <p className="font18">{description}</p>
                    )}
                </div>

                {/* Links block outside the main content div */}
                {links && (
                    <ul className="listing-block font18">
                        {links.map((link, index) => (
                            <li key={index}>
                                {link.href ? (
                                    <Link href={link.href}>{link.text}</Link>
                                ) : (
                                    <span>{link.text}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </>
        );
    };



    const services = [
        {
            id: "tab1",
            title: "AI-ML App Development",
            imgSrc: `ai-mi.svg`,
            alt: "AI and Machine Learning Application Development",
        },
        {
            id: "tab2",
            title: "VoIP Software Development",
            imgSrc: `voip.svg`,
            alt: "VoIP software icon",
        },
        {
            id: "tab3",
            title: "WebRTC Development",
            imgSrc: `webrtc-seeklogo.svg`,
            alt: "WebRTC icon",
        },
        {
            id: "tab4",
            title: "IoT Development",
            imgSrc: `IoT.svg`,
            alt: "Internet of Things (IoT) Development",
        },
        {
            id: "tab5",
            title: "Blockchain Development",
            imgSrc: `blockchain.svg`,
            alt: "Blockchain Application Development",
        },
        {
            id: "tab6",
            title: "AR-VR App Development",
            imgSrc: `camera.svg`,
            alt: "Augmented Reality and Virtual Reality Application Development",
        },
        {
            id: "tab7",
            title: "Custom Software Development",
            imgSrc: `coding.svg`,
            alt: "Custom Software Development icon",
        },
        {
            id: "tab8",
            title: "Web Application Development",
            imgSrc: `webapp.svg`,
            alt: "Web Application Development icon",
        },
        {
            id: "tab9",
            title: "Mobile App Development",
            imgSrc: `${process.env.NEXT_PUBLIC_URL_SERVICES}/mobile_app_wrap.svg`,
            alt: "Mobile Application Development",
        },
    ];

    const contentData = {
        tab1: {
            title: "AI-ML App Development",
            subtitle: "Custom AI & ML Solutions Tailored to Different Business Types",
            description: [
                "With our AI-ML development services, we integrate cutting-edge intelligence into your applications. Our specialization is developing advanced app solutions. Moreover, we are a premier AI app development company that provides you with the tools to stay ahead of competitors in an increasingly artificial intelligence-driven landscape.",
                "Our generative AI engineers are proficient in advanced technologies like GANs and neural networks to design high-quality custom models for your business. Trust us for solutions that are as effective as they are smart."
            ],
            links: [
                { text: "Natural Language Processing (NLP) Solutions" },
                { text: "Machine Learning Development" },
                { text: "Predictive Analytics Development" },
                {
                    href: "/services/ai-chatbot-development",
                    text: "AI Chatbot Integration",
                },
                { text: "Speech Recognition Systems" },
                { text: "Sentiment Analysis Applications" },
                {
                    href: "/services/ai-copilot-development",
                    text: "AI Copilot Development",
                },
                {
                    href: "/services/generative-ai-consulting",
                    text: "Generative AI Consulting",
                },
                { text: "Custom AI Solutions" },
                { href: "/services/mlops-consulting", text: "MLOps Consulting" },
                { text: "Artificial Intelligence of Things (AIoT)" },
                { href: "/services/ai-agent-development", text: "AI Agent Development" },
                { href: "/services/ai-voicebot-development", text: "AI Voice Chat Bot Development" },
                { text: "Image and Video Processing" },
            ],
        },
        tab2: {
            title: "VoIP Software Development",
            subtitle: "Enable Advanced Business Communication with VoIP",
            description: [
                "Our experts help elevate business communication capabilities through VoIP software development services. We focus on delivering efficient voice solutions designed to empower your business, which is what makes us one of the top custom VoIP app development companies.",
                "Our professionals also ensure smoother interactions and streamlined operations. Rely on us to make every business interaction more efficient and effective. This will surely transform the way you communicate in your home country and overseas.",
            ],
            links: [
                {
                    href: "/services/voip-software-development",
                    text: "Custom VoIP App Development",
                },
                { text: "VoIP Integration Services" },
                { text: "Cloud VoIP Solutions" },
                { text: "VoIP Security Services" },
                { text: "SIP Trunking Setup" },
                { text: "VoIP Softphone Development" },
                { text: "VoIP for Healthcare" },
                { text: "VoIP for Education" },
                { text: "VoIP for E-commerce" },
                { text: "VoIP for Hospitality" },
            ],
        },
        tab3: {
            title: "WebRTC Development",
            subtitle: "Real-time Connectivity with WebRTC for Business",
            description: [
                "Through AI-led WebRTC development services, we make business communication easier than ever. You can enjoy instant, high-quality interactions that take user engagement to the next level.",
                "Our team of experts stays ahead of the curve and leverages cutting-edge technologies to craft innovative WebRTC applications tailored for businesses of all sizes and industries. Trust us, and we will certainly add a touch of magic to your communication solutions. ",
            ],
            links: [
                { text: "Real-time Video Chat Solutions" },
                { text: "WebRTC Integration Services" },
                { text: "Custom WebRTC Application Development" },
                { text: "WebRTC-based Telehealth Solutions" },
                {
                    href: "/solution/telecom-software-development",
                    text: "Telecommunication Software Solutions",
                },
                { text: "WebRTC Voice Calling Solutions" },
                { text: "WebRTC for eLearning Platforms" },
                { text: "WebRTC-based Virtual Event Platforms" },
                {
                    href: "/hire-webrtc-developers",
                    text: "Dedicated WebRTC Developers For Hire",
                },
                { text: "WebRTC API Development" },
            ],
        },
        tab4: {
            title: "IoT Development",
            subtitle: "Efficient and Innovative AI-integrated IoT Solutions",
            description: [
                "We help businesses meet a smarter and more connected world through perfect AI-driven IoT development services. Our IoT expertise proves that we're not just tinkering with tech; we're creating game-changing solutions that revolutionize enterprise application development.",
                "We have a proven track record of success, and our IoT solutions have empowered businesses worldwide. Our skilled engineers are ready to execute your IoT project idea, no matter the scale or complexity.",
            ],
            links: [
                { text: "IoT Solution Architecture" },
                {
                    href: "/services/iot-development",
                    text: "IoT Application Development",
                },
                {
                    text: "IoT Data Analytics",
                },
                { text: "IoT Security Services" },
                { text: "IoT Cloud Integration" },
                { text: "Industrial IoT (IIoT) Solutions" },
                { text: "IoT Firmware Development" },
                { text: "IoT in Healthcare" },
                { href: "/portfolio/smart-iot-farming-app", text: "Smart IoT Farming" },
                {
                    href: "/hire-iot-developers",
                    text: "Dedicated IoT Developers For Hire",
                },
            ],
        },
        tab5: {
            title: "Blockchain Development",
            subtitle: "AI-driven Blockchain Development for Tomorrow's Enterprises",
            description: [
                "Our expertise combines blockchain's peerless security and decentralization with AI’s automation capabilities. This powerful combination enables our clients to optimize operations, enhance decision-making, and achieve next-level innovation.",
                "We create super-efficient solutions that elevate your enterprise security and operational efficiency. We have also expanded our blockchain services across various industries, including supply chain, gaming, healthcare, and finance. So, get ready to power up your business like never before.",
            ],
            links: [
                { text: "Smart Contract Development" },
                {
                    href: "/services/cryptocurrency-wallet-app-development",
                    text: "Cryptocurrency Wallet Development",
                },
                {
                    text: "Supply Chain Blockchain Solutions",
                },
                { text: "Tokenization and ICO Services" },
                { text: "Blockchain Integration Services" },
                {
                    href: "/services/blockchain-development",
                    text: "Blockchain App Development",
                },
                { text: "Blockchain Consulting and Auditing" },
                { text: "Healthcare Blockchain Solutions" },
                { text: "Crypto Token Development" },
                { text: "NFT Development" },
            ],
        },
        tab6: {
            title: "AR-VR App Development",
            subtitle: "Augmented Reality & Virtual Reality Apps Fueled by AI",
            description: [
                "Benefit from our specialized skills in AR-VR design and development services. We focus on creating immersive and compelling experiences that truly engage users. Moon Technolabs stands out as a leading AR-VR app development company with a solid record of delivering seamless experiences across a range of connected devices.",
                "Our expert AR/VR developers skillfully integrate IoT devices with AR/VR technologies. They are crafting innovative solutions that empower real-time data visualization, monitoring, and control.",
            ],
            links: [
                {
                    href: "/services/augmented-reality-ar-app-development",
                    text: "Augmented Reality (AR) App Development",
                },
                {
                    text: "Virtual Reality (VR) App Development",
                },
                {
                    text: "AR/VR Game Development",
                },
                { text: "Virtual Showrooms and Retail Experiences" },
                { text: "AR/VR for Sports and Entertainment" },
                { text: "AR/VR for Virtual Conferencing and Collaboration" },
                { text: "Healthcare Simulation Apps" },
                { text: "Architectural Visualization" },
                { text: "IoT in AR/VR" },
                { text: "AR/VR App Support and Maintenance" },
            ],
        },
        tab7: {
            title: "Custom Software Development",
            subtitle:
                "Custom Software Development Redefined with Artificial Intelligence",
            description: [
                "Our custom software development service creates tailored digital solutions that perfectly align with your unique business goals. We utilize modern technologies, including AI, to build not just ordinary software but also intelligent, future-ready tools that empower your vision.",
                "We are your strategic technology partners, committed to delivering outstanding solutions to our clients. Whether optimizing processes or enhancing user experiences, we turn your ideas into impactful, AI-enhanced software built for success.",
            ],
            links: [
                {
                    href: "/services/custom-software-development",
                    text: "Custom Software Solutions",
                },
                {
                    href: "/services/enterprise-software-development",
                    text: "Enterprise Software Development",
                },
                { text: "Backend System Development" },
                { text: "Software Integration Services" },
                {
                    href: "/services/legacy-software-modernization",
                    text: "Legacy System Modernization",
                },
                {
                    href: "/services/software-maintenance-support",
                    text: "Software Maintenance and Support",
                },
                {
                    href: "/services/devops-development",
                    text: "DevOps Services and Solutions",
                },
                { text: "Business Process Automation" },
                {
                    href: "/services/cloud-application-development",
                    text: "Cloud Software Development",
                },
                { text: "Software Architecture Design" },
                {
                    href: "/services/pos-software-development",
                    text: "POS Software Development",
                },
            ],
        },
        tab8: {
            title: "Web Application Development",
            subtitle: "Smart Web Applications Powered by Advanced AI",
            description: (
                <span>
                    With our web application development expertise, we mold your concepts
                    into dynamic web platforms. We're not just hitting keys and writing
                    code; we're crafting dynamic web platforms that keep users hooked and
                    drive your business growth sky-high.
                </span>
            ),
            links: [
                {
                    href: "/services/custom-software-development",
                    text: "Custom Web App Development",
                },
                {
                    href: "/services/ecommerce-development",
                    text: "E-commerce Website Development",
                },
                { text: "Web Portal Development" },
                { text: "Responsive Web Design" },
                {
                    href: "/services/cms-development",
                    text: "Content Management Systems (CMS)",
                },
                { text: "Web Application Modernization" },
                {
                    href: "/services/progressive-web-app-development",
                    text: "Progressive Web Apps (PWAs)",
                },
                { text: "Web API Development" },
                { text: "Web Performance Optimization" },
                { text: "Scalability and Load Balancing" },
            ],
        },

        tab9: {
            title: "Mobile App Development",
            subtitle: "Game-changer & AI-driven Mobile Applications for Businesses",
            description: [
                "Our mobile app services go beyond the ordinary by crafting AI-based apps tailored to your unique needs. We develop intelligent solutions that adapt, evolve, and fit your brand like a digital glove.",
                "Keeping AI at the core, we ensure your app isn't just functional but future-ready, offering a seamless, personalized experience for your users. Let’s build smarter mobile apps together to boost your business to new heights.",
            ],
            links: [
                { href: "/services/ios-app-development", text: "iOS App Development" },
                {
                    href: "/services/android-app-development",
                    text: "Android App Development",
                },
                {
                    href: "/services/cross-platform-app-development",
                    text: "Cross-Platform App Development",
                },

                {
                    href: "/services/enterprise-application-development",
                    text: "Enterprise Mobile Solutions",
                },
                {
                    href: "/services/flutter-app-development",
                    text: "Flutter App Development",
                },
                {
                    href: "/services/react-native-app-development",
                    text: "React Native App Development",
                },

                {
                    href: "/services/swift-app-development",
                    text: "Swift App Development",
                },
                {
                    href: "/services/kotlin-app-development",
                    text: "Kotlin App Development",
                },
            ],
        },
    };



    const handleTabChange = (e, tabId) => {
        e.preventDefault();
        setActiveTab(tabId);
    };

    return (
        <>
            <section className="service-wrapper pb-70 pt-70">
                <div className="container">
                    <Title text="Diversified Digital Transformation Services " />

                    <div className="service-tabblock">
                        <div className="service-tablinks">
                            <ul className="nav nav-tabs font20">
                                {services.map((service) => (
                                    <TabLink
                                        key={service.id}
                                        id={service.id}
                                        title={service.title}
                                        imgSrc={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/${service.imgSrc}`}
                                        alt={service.alt}
                                        isActive={activeTab === service.id}
                                        onClick={handleTabChange}
                                    />
                                ))}
                            </ul>
                        </div>

                        <div className="service-tabwrapper tab-content">
                            <div
                                className={`service-tabinfo tab-pane fade in ${activeTab ? "active" : ""
                                    }`}
                            >
                                <ContentBlock
                                    title={contentData[activeTab].title}
                                    subtitle={contentData[activeTab].subtitle}
                                    description={contentData[activeTab].description}
                                    links={contentData[activeTab].links}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;
