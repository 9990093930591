"use client"
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
const InfrastructureModel = dynamic(() => import("../../../components/InfrastructureModel"), {
    ssr: false,
});

const Infrastructure = () => {
    const videoUrl = "https://www.youtube.com/embed/Etrn68T-M0k";
    const [isModalOpen, setIsModalOpen] = useState(false);


    const openModal = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="col-md-6 m-0-auto">
                <div className="video-block w-100 about-video">
                    <div className="infrastructure">
                        <div className="infrastructur_inner">
                            <Link href="" className="play_button_mtpl" onClick={openModal}>
                                <Image
                                    className="lazy"
                                    width={705}
                                    height={418}
                                    unoptimized
                                    src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/team_banner.webp`}
                                    alt="Team Banner image"
                                    loading="lazy"
                                />
                                <div className="play">
                                    <div className="home-banner-info">
                                        <div className="banner-playicon">
                                            <div className="position-relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="177" height="62" viewBox="0 0 177 62" fill="none"><path d="M49 11H154C165.046 11 174 19.9543 174 31C174 42.0457 165.046 51 154 51H49V11Z" fill="#2583e9"></path><path d="M59 31C59 46.464 46.464 59 31 59C15.536 59 3 46.464 3 31C3 15.536 15.536 3 31 3C46.464 3 59 15.536 59 31Z" fill="#2583e9"></path><g opacity="0.5"><path d="M50.8421 8.85714H154.895C167.103 8.85714 177 18.7708 177 31C177 43.2292 167.103 53.1429 154.895 53.1429H50.8421V8.85714Z" fill="#2583e9"></path><path d="M61.8947 31C61.8947 48.1208 48.0391 62 30.9474 62C13.8556 62 0 48.1208 0 31C0 13.8792 13.8556 0 30.9474 0C48.0391 0 61.8947 13.8792 61.8947 31Z" fill="#2583e9"></path></g><path d="M43.3708 30.9468L24.6932 20.1629C24.3172 19.9457 23.9194 19.9457 23.5427 20.1629C23.1661 20.3804 23 20.7817 23 21.2164V42.7839C23 43.2182 23.1661 43.6199 23.5424 43.837C23.7307 43.9458 23.9076 44 24.1178 44C24.3277 44 24.5212 43.9458 24.7095 43.837L43.3792 33.0535C43.7555 32.8363 43.9831 32.4346 43.9831 32.0003C43.9831 31.5656 43.7471 31.1643 43.3708 30.9468Z" fill="white"></path></svg>
                                                <span className="bottom_video_play">Play Video</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                            <InfrastructureModel videoUrl={videoUrl} isOpen={isModalOpen} closeModal={closeModal} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Infrastructure
