"use client";

import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

const OwlCarousel = dynamic(() => import("react-owl-carousel"), { ssr: false });

const Awards_carousel = () => {
  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    lazyLoad: true,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 },
    },
  };

  const awards = [
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img001.webp `,
      alt: "Award Image 001",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img002.webp`,
      alt: "Award Image 002",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img003.webp`,
      alt: "Award Image 003",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img004.webp`,
      alt: "Award Image 004",
      link: "https://softwareoutsourcing.com/?utm_id=so_644a362f88129",
      height: "323",
      width: "323",
    },

    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img005.webp`,
      alt: "Award Image 005",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img006.webp`,
      alt: "Award Image 006",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards_img007.webp`,
      alt: "Award Image 007",
      height: "323",
      width: "323",
    },
    {
      src: `${process.env.NEXT_PUBLIC_IMAGE_URL}/${process.env.NEXT_PUBLIC_IMAGE_MAIN}/awards.webp`,
      alt: "Award Image 008",
      height: "323",
      width: "323",
    },
  ];

  return (
    <>
      <div className="award_banner_main" aria-label="Awards Carousel">
        <OwlCarousel
          className="owl-carousel owl-theme award_banner_main_wrap owl-loaded owl-drag "
          {...carouselOptions}
        >
          {awards.map((award, index) => (
            <div key={index} className="item" role="listitem">
              <div className="award_img">
                {award.link ? (
                  <Link
                    href={award.link}
                    target="_blank"
                    rel="nofollow"
                    role="link"
                  >
                    <Image
                      loading="lazy"
                      unoptimized   
                      height={award.height}
                      width={award.width}
                      src={award.src}
                      alt={award.alt}
                    />
                  </Link>
                ) : (
                  <Image
                    loading="lazy"
                    unoptimized
                    height={award.height}
                    width={award.width}
                    src={award.src}
                    alt={award.alt}
                  />
                )}
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </>
  );
};

export default Awards_carousel;
